import React from "react";
import { Button } from "@material-ui/core";
import { Link as GatsbyLink } from "gatsby";
import { ROUTES } from "../components/navigation/InternalRoute";
import LayoutCosmos from "../components/element/layout/LayoutCosmos";

const seo = {
  title: "404 Page not found - Oberion",
  description: null,
};
const heading = "404 Page not found";
const subheading = ["These links might be helpful instead:"];
const buttons = [
  <Button
    component={GatsbyLink}
    to={ROUTES.HOME.TO} variant="outlined"
    color="secondary"
    fullWidth
  >{ROUTES.HOME.CAPTION}
  </Button>,
  <Button
    component={GatsbyLink}
    to={ROUTES.BLOG.TO}
    variant="outlined"
    color="secondary"
    fullWidth
  >{ROUTES.BLOG.CAPTION}
  </Button>,
  <Button
    component={GatsbyLink}
    to={ROUTES.ABOUT.TO}
    variant="outlined"
    color="secondary"
    fullWidth
  >{ROUTES.ABOUT.CAPTION}
  </Button>,
];

const Error404 = () => (
  <LayoutCosmos seo={seo} heading={heading} subheading={subheading} buttons={buttons} />
);
export default Error404;
